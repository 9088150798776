import { Fragment } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { Button, FormInput, FormLabel, Toggle } from "../../../ui";

interface Props {
  onSubmit: any;
  location?: any;
}

export const LocationForm = ({ onSubmit, location }: Props) => {
  const {
    control,
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();
  const history = useHistory();
  const onSubmitHandler = async (data: any) => {
    const transformedData = {
      ...data,
      tax_rate: parseInt(
        parseFloat(data?.tax_rate).toFixed(2).replace(".", ""),
        10,
      ),
      total_kiosks: parseInt(data.total_kiosks),
      guest_experience_phone_number: data.guest_experience_phone_number
        ? data.guest_experience_phone_number.replace(/\D/g, "")
        : "",
      phone_number: data.phone_number
        ? data.phone_number.replace(/\D/g, "")
        : "",
      are_drinks_self_serve: data.are_drinks_self_serve ? true : false,
    };
    await onSubmit(transformedData);
  };

  const toggleHandler = (formKey: string) => {
    const formVal = getValues(formKey);
    setValue(formKey, formVal === "undefined" ? false : !formVal);
  };

  return (
    <form onSubmit={handleSubmit(onSubmitHandler)}>
      <div className="grid grid-cols-8 gap-6 my-5">
        <div className="lg:col-span-2 md:col-span-3 col-span-8">
          <FormLabel
            title="Name"
            htmlFor="name"
            information="The name of the new location. It is usually the name of the city."
          />
          <Controller
            name="name"
            control={control}
            defaultValue={!!location ? location.name : ""}
            rules={{ required: true }}
            render={({ field }) => <FormInput type="text" {...field} />}
          />
          {errors?.name?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
        </div>
        <div className="lg:col-span-2 md:col-span-3 col-span-8">
          <FormLabel
            title="Timezone"
            htmlFor="timezone"
            information='Timezone of the location. If this location is in California, leave as "America/Los_Angeles"'
          />
          <Controller
            name="timezone"
            control={control}
            rules={{ required: true }}
            defaultValue={
              !!location ? location.timezone : "America/Los_Angeles"
            }
            render={({ field }) => <FormInput type="text" {...field} />}
          />
          {errors?.timezone?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
        </div>
        <div className="lg:col-span-2 md:col-span-3 col-span-8">
          <FormLabel title="Phone Number" htmlFor="phone_number" />
          <Controller
            name="phone_number"
            control={control}
            rules={{
              required: true,
              pattern:
                /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
            }}
            defaultValue={!!location ? location.phone_number : ""}
            render={({ field }) => <FormInput type="tel" {...field} />}
          />
          {errors?.phone_number?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
          {errors?.phone_number?.type === "pattern" && (
            <p className="text-red-600">Please enter a valid phone number</p>
          )}
        </div>
        <div className="lg:col-span-2 md:col-span-3 col-span-4">
          <FormLabel
            title="Is Dine-In Availabile"
            htmlFor="is_dine_in_available"
            information="Whether or not guests can sit and eat at the location (e.g. are there tables?)"
          />
          <Controller
            name="is_dine_in_available"
            control={control}
            rules={{ required: false }}
            defaultValue={
              !!location && location.is_dine_in_available ? true : false
            }
            render={({ field }) => {
              return (
                <Fragment>
                  <Toggle
                    initialValue={field.value ? true : false}
                    onChange={() => toggleHandler("is_dine_in_available")}
                  />
                  <span className={!field.value ? "font-bold" : ""}>No</span> /{" "}
                  <span className={field.value ? "font-bold" : ""}>Yes</span>
                </Fragment>
              );
            }}
          />
        </div>
        <div className="lg:col-span-3 md:col-span-4 col-span-8">
          <FormLabel
            title="Street"
            htmlFor="street"
            information="Street Address of this location (e.g. 3455 Mount Diablo Boulevard)"
          />
          <Controller
            name="street"
            control={control}
            rules={{ required: true }}
            defaultValue={!!location ? location.street : ""}
            render={({ field }) => <FormInput type="text" {...field} />}
          />
          {errors?.street?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
        </div>
        <div className="lg:col-span-1 md:col-span-2 col-span-4">
          <FormLabel title="State" htmlFor="state" />
          <Controller
            name="state"
            defaultValue={!!location ? location.state : "CA"}
            control={control}
            rules={{ required: true }}
            render={({ field }) => <FormInput type="text" {...field} />}
          />
          {errors?.state?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
        </div>
        <div className="lg:col-span-2 md:col-span-3 col-span-4">
          <FormLabel title="Zip Code" htmlFor="zipcode" />
          <Controller
            name="zipcode"
            control={control}
            rules={{ required: true }}
            defaultValue={!!location ? location.zipcode : ""}
            render={({ field }) => <FormInput type="text" {...field} />}
          />
          {errors?.zipcode?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
        </div>
        <div className="lg:col-span-1 md:col-span-2 col-span-4">
          <FormLabel
            title="Tax Rate"
            htmlFor="tax_rate"
            information="Tax Rate at this location in the form of 8.25"
          />
          <div className="relative">
            <input
              type="text"
              id="tax_rate"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-10 sm:text-sm border-gray-300 rounded-md"
              placeholder="0.00"
              defaultValue={
                !!location ? (location.tax_rate / 100).toString() : ""
              }
              aria-describedby="tax_rate"
              {...register("tax_rate", {
                required: true,
                pattern: /^[+-]?[0-9]{1,3}(?:,?[0-9]{3})*\.[0-9]{1,2}$/,
              })}
            />
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm" id="tax_rate">
                %
              </span>
            </div>
          </div>
          {errors?.tax_rate?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
        </div>
        <div className="lg:col-span-2 md:col-span-3 col-span-8">
          <FormLabel
            title="Stripe Location ID"
            htmlFor="stripe_location_id"
            information="The location id provided by Stripe. You need this location created in Stripe prior to having this field."
          />
          <Controller
            name="stripe_location_id"
            control={control}
            rules={{ required: true }}
            defaultValue={!!location ? location.stripe_location_id : ""}
            render={({ field }) => <FormInput type="text" {...field} />}
          />
          {errors?.stripe_location_id?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
        </div>
        <div className="lg:col-span-2 md:col-span-3 col-span-8">
          <FormLabel
            title="Toast Location ID"
            htmlFor="toast_location_id"
            information="The location id provided by Toast. You need this location created in Stripe prior to having this field."
          />
          <Controller
            name="toast_location_id"
            control={control}
            rules={{ required: true }}
            defaultValue={!!location ? location.toast_location_id : ""}
            render={({ field }) => <FormInput type="text" {...field} />}
          />
          {errors?.toast_location_id?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
        </div>
        <div className="lg:col-span-3 md:col-span-4 col-span-8">
          <FormLabel
            title="Share Link"
            htmlFor="share_link"
            information="The link that will be used to share this location - from Google Maps. For example, San Jose's link is: https://goo.gl/maps/ZQLMjRrKcwcJuTDX9"
          />
          <Controller
            name="share_link"
            control={control}
            rules={{ required: true }}
            defaultValue={!!location ? location.share_link : ""}
            render={({ field }) => <FormInput type="text" {...field} />}
          />
          {errors?.share_link?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
        </div>
        <div className="lg:col-span-3 md:col-span-4 col-span-8">
          <FormLabel
            title="Google Place ID"
            htmlFor="google_place_id"
            information={
              <div>
                <p>
                  The id that will be used to generate a review link to this
                  location. You can find the place id for a given location using
                  this tool:
                </p>
                <a href="https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder">
                  https://developers.google.com/maps/documentation/javascript/examples/places-placeid-finder
                </a>
                <p>
                  For example, Mill Valley's place id is:
                  ChIJyYd4W86bhYARIb9j4g2SPjY
                </p>
              </div>
            }
          />
          <Controller
            name="google_place_id"
            control={control}
            rules={{ required: true }}
            defaultValue={!!location ? location.google_place_id : ""}
            render={({ field }) => <FormInput type="text" {...field} />}
          />
          {errors?.google_place_id?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
        </div>
        <div className="lg:col-span-3 md:col-span-5 col-span-8">
          <FormLabel
            title="Guest Experience Phone Number"
            htmlFor="guest_experience_phone_number"
            information="Phone number that guests will use to contact guest experience."
          />
          <Controller
            name="guest_experience_phone_number"
            control={control}
            rules={{
              required: false,
              pattern:
                /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
            }}
            defaultValue={
              !!location && location.guest_experience_phone_number
                ? location.guest_experience_phone_number
                : "4159928206"
            }
            render={({ field }) => <FormInput type="tel" {...field} />}
          />
          {errors?.guest_experience_phone_number?.type === "pattern" && (
            <p className="text-red-600">Please enter a valid phone number</p>
          )}
        </div>
        <div className="lg:col-span-3 md:col-span-5 col-span-8">
          <FormLabel
            title="Catering Link"
            htmlFor="catering_link"
            information="Catering link to be used by `Order Catering` CTA button in guest-web"
          />
          <Controller
            name="catering_link"
            control={control}
            rules={{
              required: true,
            }}
            defaultValue={
              !!location && location.catering_link
                ? location.catering_link
                : "https://www.ezcater.com/brand/pvt/local-kitchens"
            }
            render={({ field }) => <FormInput type="tel" {...field} />}
          />
          {errors?.catering_link && (
            <p className="text-red-600">Please enter a valid catering link</p>
          )}
        </div>
        <div className="lg:col-span-1 md:col-span-2 col-span-4">
          <FormLabel
            title="Total Kiosks"
            htmlFor="total_kiosks"
            information="Total number of in store kiosks at this location"
          />
          <div className="relative">
            <input
              type="number"
              id="total_kiosks"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              aria-describedby="total_kiosks"
              min="0"
              max="100"
              defaultValue={
                !!location && location.total_kiosks ? location.total_kiosks : 0
              }
              {...register("total_kiosks", { required: true })}
            />
          </div>
          {errors?.total_kiosks?.type === "required" && (
            <p className="text-red-600">This field is required</p>
          )}
        </div>
        <div className="lg:col-span-2 md:col-span-3 col-span-4">
          <FormLabel
            title="Are drinks self-serve?"
            htmlFor="are_drinks_self_serve"
            information="Do guests grab their own drinks from a fridge at this location? If this flag is set to true, guests will be directed to grab their drinks at checkout."
          />
          <Controller
            name="are_drinks_self_serve"
            control={control}
            rules={{ required: false }}
            defaultValue={
              !!location && location.are_drinks_self_serve ? true : false
            }
            render={({ field }) => {
              return (
                <Fragment>
                  <Toggle
                    initialValue={field.value ? true : false}
                    onChange={() => toggleHandler("are_drinks_self_serve")}
                  />
                  <span className={!field.value ? "font-bold" : ""}>No</span> /{" "}
                  <span className={field.value ? "font-bold" : ""}>Yes</span>
                </Fragment>
              );
            }}
          />
        </div>
      </div>
      <Button className="mr-3">Save</Button>
      <Button
        backgroundColor="bg-gray-500"
        type="button"
        onClick={
          !!location
            ? () => history.push(`/locations/${location.slug}`)
            : () => history.push("/locations")
        }
      >
        Cancel
      </Button>
    </form>
  );
};
